import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import {
  airFreightIcon,
  analyticsIcon,
  boxIcon,
  briefcaseIcon,
  checkListIcon,
  closeIcon,
  companiesIcon,
  conversationIcon,
  documentsIcon,
  graphChartIcon, halfMoonIcon,
  handBookIcon,
  liveVideoIcon,
  mapNavigationIcon,
  newspaperIcon,
  peopleIcon,
  pipelineIcon,
  requestsIcon,
  shipIcon,
  sunLightIcon,
  truckIcon,
  userCircleIcon,
  userCollaborateIcon,
  userPlusIcon
} from "../../../assets/images/pages/menu-icons/menu-icons";
import {
  adjustmentsAltIcon,
  airplaneStatusIcon,
  attachmentIcon,
  boxIsoIcon,
  briefcaseLineIcon,
  buildingsIcon,
  calendarClockIcon,
  calendarMonthIcon,
  callIcon,
  checkboxIcon,
  checksIcon,
  chevronRightIcon,
  commentIcon,
  copyIcon,
  copyLinkIcon,
  craneIcon,
  envelopeIcon,
  exclamationCircleIcon,
  filterLinesIcon,
  generalNoteIcon,
  googleMapsPinIcon,
  heartIcon,
  heartSolidIcon,
  historyIcon,
  horizontalDotsIcon,
  listDetailsIcon,
  locationIcon,
  locationRequestIcon,
  meetingIcon,
  messageEnabled,
  messageOffIcon,
  moneyReceiveIcon,
  numberIcon,
  pencilAltIcon,
  pencilIcon,
  phoneIcon,
  photoIcon,
  pictureIcon,
  routeIcon,
  sendMessageIcon,
  shipStatusIcon, solar_bellFilled, solar_bellOutline,
  textSizeIcon,
  trashIcon,
  truckStatusIcon,
  userSquareIcon,
  verticalDotsIcon,
  whatsappIcon
} from "../../../assets/images/pages/icons/icons";
import {
  solar_alarmOutline, solar_altArrowRightBold,
  solar_boardFill,
  solar_buildings2Outline,
  solar_buildings3Outline,
  solar_calendarDaysOutline,
  solar_calendarOutline,
  solar_caseRoundOutline,
  solar_chatLineOutline,
  solar_checklistBrokenOutline,
  solar_checklistOutline,
  solar_checkSquareBrokenOutline,
  solar_clipboardOutline,
  solar_clockCircleOutline,
  solar_dangerSquareOutline,
  solar_documentAddLinearOutline,
  solar_documentOutline,
  solar_downloadOutline,
  solar_emojiFunnySquareOutline,
  solar_filterOutline, solar_flagOutline,
  solar_folderWithFilesMenuOutline,
  solar_folderWithFilesOutline,
  solar_galleryAddOutline, solar_historyOutline,
  solar_letterLinearOutline,
  solar_letterOutline,
  solar_linkMinimalisticOutline,
  solar_linkOutline,
  solar_listCheckOutline,
  solar_mapPointOutline, solar_maximizeOutline,
  solar_maximizeSquare3Outline,
  solar_menuDotsBold, solar_minimizeOutline,
  solar_minimizeSquare3Outline,
  solar_notebookSquareMenuOutline,
  solar_notesLinearOutline,
  solar_paperclipOutline, solar_penRoundOutline, solar_phoneOutline,
  solar_phoneRoundedOutline,
  solar_plainOutline,
  solar_plusSign,
  solar_refreshOutline,
  solar_reorderLinearOutline,
  solar_roundGraphOutline,
  solar_scaleOutline,
  solar_searchOutline, solar_shareOutline,
  solar_sortVerticalOutline,
  solar_squareShareLineOutline,
  solar_tableOutline, solar_taskOutline,
  solar_textSquareOutline,
  solar_trafficOutline,
  solar_trashBin2Outline,
  solar_userCircleOutline,
  solar_userOutline,
  solar_userPlusBrokenOutline,
  solar_usersGroupRoundedOutline,
  solar_widgetAddOutline
} from "../../../assets/icons/custom-icons";

@Injectable({
  providedIn: 'root',
})

export class IconsService {

  constructor(
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
  ) {}

  initIcons(): void {
    this.iconRegistry.addSvgIconLiteral(
      'briefcase',
      this.sanitizer.bypassSecurityTrustHtml(briefcaseIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'briefcase_line',
      this.sanitizer.bypassSecurityTrustHtml(briefcaseLineIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'newspaper',
      this.sanitizer.bypassSecurityTrustHtml(newspaperIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'people',
      this.sanitizer.bypassSecurityTrustHtml(peopleIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'liveVideo',
      this.sanitizer.bypassSecurityTrustHtml(liveVideoIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'conversation',
      this.sanitizer.bypassSecurityTrustHtml(conversationIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'ship',
      this.sanitizer.bypassSecurityTrustHtml(shipIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'checkList',
      this.sanitizer.bypassSecurityTrustHtml(checkListIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'usersPlus',
      this.sanitizer.bypassSecurityTrustHtml(userPlusIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'close',
      this.sanitizer.bypassSecurityTrustHtml(closeIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'documents',
      this.sanitizer.bypassSecurityTrustHtml(documentsIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'graphChart',
      this.sanitizer.bypassSecurityTrustHtml(graphChartIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'airFreight',
      this.sanitizer.bypassSecurityTrustHtml(airFreightIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'truck',
      this.sanitizer.bypassSecurityTrustHtml(truckIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'box',
      this.sanitizer.bypassSecurityTrustHtml(boxIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'analytics',
      this.sanitizer.bypassSecurityTrustHtml(analyticsIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'mapNavigation',
      this.sanitizer.bypassSecurityTrustHtml(mapNavigationIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'companies',
      this.sanitizer.bypassSecurityTrustHtml(companiesIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'userCollaborate',
      this.sanitizer.bypassSecurityTrustHtml(userCollaborateIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'handBook',
      this.sanitizer.bypassSecurityTrustHtml(handBookIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'pipeline',
      this.sanitizer.bypassSecurityTrustHtml(pipelineIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'requests',
      this.sanitizer.bypassSecurityTrustHtml(requestsIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'verticalDots',
      this.sanitizer.bypassSecurityTrustHtml(verticalDotsIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'trash',
      this.sanitizer.bypassSecurityTrustHtml(trashIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'copy',
      this.sanitizer.bypassSecurityTrustHtml(copyIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'envelope',
      this.sanitizer.bypassSecurityTrustHtml(envelopeIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'whatsapp',
      this.sanitizer.bypassSecurityTrustHtml(whatsappIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'buildings',
      this.sanitizer.bypassSecurityTrustHtml(buildingsIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'location',
      this.sanitizer.bypassSecurityTrustHtml(locationIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'phone',
      this.sanitizer.bypassSecurityTrustHtml(phoneIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'sendMessage',
      this.sanitizer.bypassSecurityTrustHtml(sendMessageIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'photo',
      this.sanitizer.bypassSecurityTrustHtml(photoIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'attachmentIcon',
      this.sanitizer.bypassSecurityTrustHtml(attachmentIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'pencilAlt',
      this.sanitizer.bypassSecurityTrustHtml(pencilAltIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'horizontalDots',
      this.sanitizer.bypassSecurityTrustHtml(horizontalDotsIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'pencil',
      this.sanitizer.bypassSecurityTrustHtml(pencilIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'heart',
      this.sanitizer.bypassSecurityTrustHtml(heartIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'heartSolid',
      this.sanitizer.bypassSecurityTrustHtml(heartSolidIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'comment',
      this.sanitizer.bypassSecurityTrustHtml(commentIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'copyLink',
      this.sanitizer.bypassSecurityTrustHtml(copyLinkIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'chevronRight',
      this.sanitizer.bypassSecurityTrustHtml(chevronRightIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'userSquare',
      this.sanitizer.bypassSecurityTrustHtml(userSquareIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'picture',
      this.sanitizer.bypassSecurityTrustHtml(pictureIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'listDetails',
      this.sanitizer.bypassSecurityTrustHtml(listDetailsIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'userCircle',
      this.sanitizer.bypassSecurityTrustHtml(userCircleIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'googleMapsPin',
      this.sanitizer.bypassSecurityTrustHtml(googleMapsPinIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'truckStatus',
      this.sanitizer.bypassSecurityTrustHtml(truckStatusIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'exclamationCircle',
      this.sanitizer.bypassSecurityTrustHtml(exclamationCircleIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'messageOff',
      this.sanitizer.bypassSecurityTrustHtml(messageOffIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'messageEnabled',
      this.sanitizer.bypassSecurityTrustHtml(messageEnabled),
    );
    this.iconRegistry.addSvgIconLiteral(
      'sunLight',
      this.sanitizer.bypassSecurityTrustHtml(sunLightIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'halfMoon',
      this.sanitizer.bypassSecurityTrustHtml(halfMoonIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'route',
      this.sanitizer.bypassSecurityTrustHtml(routeIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'moneyReceive',
      this.sanitizer.bypassSecurityTrustHtml(moneyReceiveIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'boxIso',
      this.sanitizer.bypassSecurityTrustHtml(boxIsoIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'shipStatus',
      this.sanitizer.bypassSecurityTrustHtml(shipStatusIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'airplaneStatus',
      this.sanitizer.bypassSecurityTrustHtml(airplaneStatusIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'calendarClock',
      this.sanitizer.bypassSecurityTrustHtml(calendarClockIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'crane',
      this.sanitizer.bypassSecurityTrustHtml(craneIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'generalNote',
      this.sanitizer.bypassSecurityTrustHtml(generalNoteIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'meeting',
      this.sanitizer.bypassSecurityTrustHtml(meetingIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'callSummary',
      this.sanitizer.bypassSecurityTrustHtml(callIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'locationRequest',
      this.sanitizer.bypassSecurityTrustHtml(locationRequestIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'history',
      this.sanitizer.bypassSecurityTrustHtml(historyIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'adjustmentsAlt',
      this.sanitizer.bypassSecurityTrustHtml(adjustmentsAltIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'calendarMonth',
      this.sanitizer.bypassSecurityTrustHtml(calendarMonthIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'number',
      this.sanitizer.bypassSecurityTrustHtml(numberIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'textSize',
      this.sanitizer.bypassSecurityTrustHtml(textSizeIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'checkbox',
      this.sanitizer.bypassSecurityTrustHtml(checkboxIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'filterLines',
      this.sanitizer.bypassSecurityTrustHtml(filterLinesIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'checks',
      this.sanitizer.bypassSecurityTrustHtml(checksIcon),
    );
    this.iconRegistry.addSvgIconLiteral(
      'solar_letterLinearOutline',
      this.sanitizer.bypassSecurityTrustHtml(solar_letterLinearOutline),
    )
    this.iconRegistry.addSvgIconLiteral(
      'solar_calendarOutline',
      this.sanitizer.bypassSecurityTrustHtml(solar_calendarOutline),
    )
    this.iconRegistry.addSvgIconLiteral(
      'solar_sortVerticalOutline',
      this.sanitizer.bypassSecurityTrustHtml(solar_sortVerticalOutline),
    )
    this.iconRegistry.addSvgIconLiteral(
      'solar_bellOutline',
      this.sanitizer.bypassSecurityTrustHtml(solar_bellOutline),
    );
    this.iconRegistry.addSvgIconLiteral(
      'solar_bellFilled',
      this.sanitizer.bypassSecurityTrustHtml(solar_bellFilled),
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_buildings3Outline",
      this.sanitizer.bypassSecurityTrustHtml(solar_buildings3Outline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_buildings2Outline",
      this.sanitizer.bypassSecurityTrustHtml(solar_buildings2Outline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_userCircleOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_userCircleOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_userOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_userOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_mapPointOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_mapPointOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_caseRoundOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_caseRoundOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_linkMinimalisticOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_linkMinimalisticOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_letterOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_letterOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_phoneRoundedOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_phoneRoundedOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_clockCircleOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_clockCircleOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_clipboardOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_clipboardOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_plusSign",
      this.sanitizer.bypassSecurityTrustHtml(solar_plusSign)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_userPlusBrokenOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_userPlusBrokenOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_calendarOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_calendarOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_paperclipOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_paperclipOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_emojiFunnySquareOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_emojiFunnySquareOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_textSquareOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_textSquareOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_checklistBrokenOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_checklistBrokenOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_calendarDaysOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_calendarDaysOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_listCheckOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_listCheckOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_checkSquareBrokenOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_checkSquareBrokenOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_checklistOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_checklistOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_documentAddLinearOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_documentAddLinearOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_notesLinearOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_notesLinearOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_galleryAddOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_galleryAddOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_menuDotsBold",
      this.sanitizer.bypassSecurityTrustHtml(solar_menuDotsBold)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_squareShareLineOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_squareShareLineOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_alarmOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_alarmOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_trashBin2Outline",
      this.sanitizer.bypassSecurityTrustHtml(solar_trashBin2Outline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_plainOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_plainOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_folderWithFilesMenuOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_folderWithFilesMenuOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_folderWithFilesOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_folderWithFilesOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_scaleOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_scaleOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_maximizeSquare3Outline",
      this.sanitizer.bypassSecurityTrustHtml(solar_maximizeSquare3Outline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_minimizeSquare3Outline",
      this.sanitizer.bypassSecurityTrustHtml(solar_minimizeSquare3Outline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_searchOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_searchOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_filterOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_filterOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_downloadOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_downloadOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_documentOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_documentOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_notebookSquareMenuOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_notebookSquareMenuOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_linkOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_linkOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_dangerSquareOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_dangerSquareOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_chatLineOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_chatLineOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_reorderLinearOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_reorderLinearOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_boardFill",
      this.sanitizer.bypassSecurityTrustHtml(solar_boardFill)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_tableOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_tableOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_usersGroupRoundedOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_usersGroupRoundedOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_trafficOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_trafficOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_widgetAddOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_widgetAddOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_roundGraphOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_roundGraphOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_refreshOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_refreshOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_maximizeOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_maximizeOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_minimizeOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_minimizeOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_shareOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_shareOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_penRoundOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_penRoundOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_historyOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_historyOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_taskOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_taskOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_flagOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_flagOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_altArrowRightBold",
      this.sanitizer.bypassSecurityTrustHtml(solar_altArrowRightBold)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_phoneOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_phoneOutline)
    );
  }
}
