import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'app/types/user/user.types';
import { map, Observable, ReplaySubject, take } from 'rxjs';
import {
  QUERY_GET_SOCIAL_TERMS,
  QUERY_GET_USER_DATA,
} from '../../core/graphql/queries';
import { Apollo } from 'apollo-angular';
import {
  MUTATION_ACCEPT_SOCIAL_TERMS,
  MUTATION_SET_BIRTHDAY,
} from '../../core/graphql/mutations';
import {environment} from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _apollo: Apollo,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<User> {
    return this._user.asObservable();
  }

  update(user: User): Observable<any> {
    return this._httpClient.patch<User>('api/common/user', { user }).pipe(
      map((response) => {
        this._user.next(response);
      }),
    );
  }

  getUserDataById(userId: string): Observable<User> {
    return this._apollo
      .query({
        query: QUERY_GET_USER_DATA,
        variables: {
          userId,
        },
      })
      .pipe(map((result: any) => result.data.getUserData));
    // this._apollo
    //   .query({
    //     query: QUERY_GET_USER_DATA,
    //     variables: {
    //       userId: response.data.login.userId,
    //     },
    //   })
    //   .subscribe((user: any) => {
    //     this._userService.user = user.data.getUserData;
    //     this._authenticated = true;
    //   });
  }

  setBirthday(
    frachtBranchMemberId: string,
    birthday: string,
  ): Observable<boolean> {
    return this._apollo
      .mutate({
        mutation: MUTATION_SET_BIRTHDAY,
        variables: {
          frachtBranchMemberId,
          birthday,
        },
      })
      .pipe(map((result: any) => result.data.setBirthday));
  }

  acceptSocialTerms(): Observable<boolean> {
    return this._apollo
      .mutate({
        mutation: MUTATION_ACCEPT_SOCIAL_TERMS,
      })
      .pipe(map((result: any) => result.data.acceptSocialTerms));
  }

  getSocialTerms(): Observable<any> {
    return this._apollo
      .query({
        query: QUERY_GET_SOCIAL_TERMS,
      })
      .pipe(map((result: any) => result.data.getSocialTerms));
  }

  getWorkloadUsers(): Observable<any> {
    return this._httpClient.get(`${environment.workloadApiUrl}/network/users`);
  }
}
