/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { solar_reorderLinearOutline, solar_taskOutline } from "../../../../assets/icons/custom-icons";
import {AppModulesEnum, WorkloadUserRolesEnum} from '../../../types/user/user.types';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'communication',
    title: 'Communication',
    type: 'group',
    icon: 'heroicons_outline:briefcase',
    children: [
      {
        id: 'news',
        title: 'Social',
        type: 'basic',
        icon: 'newspaper',
        link: '/social',
      },
      {
        id: 'companyNews',
        title: 'Company News',
        type: 'basic',
        icon: 'people',
        link: '/company-news',
      },
      {
        id: 'organisationFrachtContacts',
        title: 'Contacts',
        type: 'basic',
        icon: 'handBook',
        link: '/organisation/offices',
      },
      {
        id: 'organisationFrachtAgents',
        title: 'Offices',
        type: 'basic',
        icon: 'briefcase',
        link: '/organisation/map',
      },
      {
        id: 'chat',
        title: 'Chat',
        type: 'basic',
        icon: 'conversation',
        link: '/conversations',
        // externalLink: true,
        // target: '_blank',
      },
    ],
  },
  {
    id: 'sales',
    title: 'Sales',
    type: 'group',
    icon: 'heroicons_outline:home',
    children: [
      {
        id: 'pipeline',
        title: 'Pipeline',
        type: 'basic',
        icon: 'pipeline',
        link: '/sales/pipeline',
      },
      {
        id: 'requests',
        title: 'Requests',
        type: 'basic',
        icon: 'requests',
        link: '/sales/requests',
      },
    ],
  },
  {
    id: 'crm',
    title: 'CRM',
    meta: {
      module: AppModulesEnum.CRM,
    },
    type: 'group',
    icon: 'heroicons_outline:home',
    children: [
      {
        id: 'accounts',
        title: 'Accounts',
        type: 'basic',
        icon: 'solar_folderWithFilesMenuOutline',
        link: '/crm/accounts',
      },
      {
        id: 'contacts',
        title: 'Contacts',
        type: 'basic',
        icon: 'solar_notebookSquareMenuOutline',
        link: '/crm/contacts',
      },
      {
        id: 'opportunities',
        title: 'Opportunities',
        type: 'basic',
        icon: 'solar_reorderLinearOutline',
        link: '/crm/opportunities',
      },
      {
        id: 'tasks',
        title: 'Tasks',
        type: 'basic',
        icon: 'solar_taskOutline',
        link: '/crm/tasks',
      },
    ],
  },
  {
    id: 'operational',
    title: 'Operational',
    type: 'group',
    icon: 'heroicons_outline:home',
    children: [
      {
        id: 'seaFreight',
        title: 'Sea Freight',
        type: 'collapsable',
        icon: 'ship',
        link: '/sea-freight',
        children: [
          {
            id: 'seaFreightOrders',
            title: 'Shipments',
            type: 'basic',
            icon: 'checkList',
            link: '/sea-freight/shipments',
          },
          {
            id: 'seaFreightTransporters',
            title: 'Partners',
            type: 'basic',
            icon: 'usersPlus',
            link: '/sea-freight/partners',
          },
          {
            id: 'seaFreightBankingDetails',
            title: 'Banking Partners',
            type: 'basic',
            icon: 'usersPlus',
            link: '/sea-freight/banking-partners',
          },
          {
            id: 'seaFreightInvoices',
            title: 'Invoices',
            type: 'basic',
            icon: 'documents',
            link: '/sea-freight/invoices',
          },
          {
            id: 'seaFreightFinancials',
            title: 'Financials',
            type: 'basic',
            icon: 'graphChart',
            link: '/sea-freight/financials',
          },
        ],
      },
      {
        id: 'airFreight',
        title: 'Air Freight',
        type: 'collapsable',
        icon: 'airFreight',
        link: '/air-freight',
        children: [
          {
            id: 'airFreightOrders',
            title: 'Shipments',
            type: 'basic',
            icon: 'checkList',
            link: '/air-freight/shipments',
          },
          {
            id: 'airFreightOrders',
            title: 'Waybills',
            type: 'basic',
            icon: 'checkList',
            link: '/air-freight/air-transport-order',
          },
          {
            id: 'airFreightTransporters',
            title: 'Partners',
            type: 'basic',
            icon: 'usersPlus',
            link: '/air-freight/partners',
          },
          {
            id: 'airFreightBankingDetails',
            title: 'Banking Partners',
            type: 'basic',
            icon: 'usersPlus',
            link: '/air-freight/banking-partners',
          },
          {
            id: 'airFreightInvoices',
            title: 'Invoices',
            type: 'basic',
            icon: 'documents',
            link: '/air-freight/invoices',
          },
          {
            id: 'airFreightFinancials',
            title: 'Financials',
            type: 'basic',
            icon: 'graphChart',
            link: '/air-freight/financials',
          },
        ],
      },
      {
        id: 'truckFreight',
        title: 'Truck Freight',
        type: 'collapsable',
        icon: 'truck',
        link: '/truck-freight',
        children: [
          {
            id: 'truckFreightOrders',
            title: 'Orders',
            type: 'basic',
            icon: 'checkList',
            link: '/order',
          },
          {
            id: 'truckFreightLoads',
            title: 'Loads',
            type: 'basic',
            icon: 'box',
            link: '/load',
          },
          {
            id: 'financials',
            title: 'Financials',
            type: 'basic',
            icon: 'graphChart',
            link: '/financials',
          },
          {
            id: 'truckFreightTruckStatus',
            title: 'Truck Status',
            type: 'basic',
            icon: 'analytics',
            link: '/truck-status',
          },
          {
            id: 'truckFreightLiveTrucks',
            title: 'Live Trucks',
            type: 'basic',
            icon: 'mapNavigation',
            link: '/localization',
          },
        ],
      },
      // {
      //     id: 'companies',
      //     title: 'Companies',
      //     type: 'basic',
      //     icon: 'companies',
      //     link: '/companies',
      // },
      {
        id: 'clients',
        title: 'Clients',
        type: 'basic',
        icon: 'userCollaborate',
        link: '/admin/clients',
      },
      {
        id: 'financialCosts',
        title: 'Financials',
        type: 'basic',
        icon: 'graphChart',
        link: '/financial-costs',
      },
    ],
  },
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Dashboards',
    tooltip: 'Dashboards',
    type: 'aside',
    icon: 'heroicons_outline:home',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'apps',
    title: 'Apps',
    tooltip: 'Apps',
    type: 'aside',
    icon: 'heroicons_outline:qrcode',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'pages',
    title: 'Pages',
    tooltip: 'Pages',
    type: 'aside',
    icon: 'heroicons_outline:document-duplicate',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'user-interface',
    title: 'UI',
    tooltip: 'UI',
    type: 'aside',
    icon: 'heroicons_outline:rectangle-stack',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'navigation-features',
    title: 'Navigation',
    tooltip: 'Navigation',
    type: 'aside',
    icon: 'heroicons_outline:bars-3',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'DASHBOARDS',
    type: 'group',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'apps',
    title: 'APPS',
    type: 'group',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'others',
    title: 'OTHERS',
    type: 'group',
  },
  {
    id: 'pages',
    title: 'Pages',
    type: 'aside',
    icon: 'heroicons_outline:document-duplicate',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'user-interface',
    title: 'User Interface',
    type: 'aside',
    icon: 'heroicons_outline:rectangle-stack',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'navigation-features',
    title: 'Navigation Features',
    type: 'aside',
    icon: 'heroicons_outline:bars-3',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Dashboards',
    type: 'group',
    icon: 'heroicons_outline:home',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'apps',
    title: 'Apps',
    type: 'group',
    icon: 'heroicons_outline:qrcode',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'pages',
    title: 'Pages',
    type: 'group',
    icon: 'heroicons_outline:document-duplicate',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'user-interface',
    title: 'UI',
    type: 'group',
    icon: 'heroicons_outline:rectangle-stack',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'navigation-features',
    title: 'Misc',
    type: 'group',
    icon: 'heroicons_outline:bars-3',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
