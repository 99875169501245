import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { environment } from 'environments/environment';
import { result } from 'lodash';
import {BehaviorSubject, lastValueFrom, map, Observable, of, ReplaySubject, switchMap, take, tap} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  private _notifications: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([]);
  private eventSource: EventSource | null = null;
  private token: string = localStorage.getItem('yl_accessToken');

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _zone: NgZone,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for notifications
   */
  get notifications$(): Observable<Notification[]> {
    return this._notifications.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all notifications
   */
  getAll(userId: string = null): Observable<Notification[]> {
    if (!userId){
      return;
    }
    const url = `${environment.apiUrl}/notifications/${userId}/notifications?page=1&limit=10`;
    this._httpClient.get<Notification[]>(url).subscribe(notifications => {
      this._notifications.next(notifications);
    });    
  }

  /**
   * Create a notification
   *
   * @param notification
   */
  create(notification: Notification): Observable<Notification> {
    return this.notifications$.pipe(
      take(1),
      switchMap((notifications) =>
        this._httpClient
          .post<Notification>('api/common/notifications', { notification })
          .pipe(
            map((newNotification) => {
              // Update the notifications with the new notification
              this._notifications.next([...notifications, newNotification]);

              // Return the new notification from observable
              return newNotification;
            }),
          ),
      ),
    );
  }

  /**
   * Update the notification
   *
   * @param id
   * @param notification
   */
  update(id: string, notification: Notification): Observable<Notification> {
    return this.notifications$.pipe(
      take(1),
      switchMap((notifications) =>
        this._httpClient
          .patch<Notification>('api/common/notifications', {
            id,
            notification,
          })
          .pipe(
            map((updatedNotification: Notification) => {
              // Find the index of the updated notification
              const index = notifications.findIndex((item) => item.id === id);

              // Update the notification
              notifications[index] = updatedNotification;

              // Update the notifications
              this._notifications.next(notifications);

              // Return the updated notification
              return updatedNotification;
            }),
          ),
      ),
    );
  }

  markAsRead(notification: Notification){
    this._httpClient.patch<boolean>(`${environment.apiUrl}/notifications/${notification.id}/read`, {}).subscribe(result =>{
      if (result){
        notification.read = true;
        this._notifications.next(this._notifications.value)
      }
    })
  }

  addNotifications(addedNotification: Notification): Observable<Notification[]> {
    return this.notifications$.pipe(
      take(1),
      switchMap((notifications) => {
        let updatedNotifications: Notification[] = [...notifications, addedNotification];
        this._notifications.next(updatedNotifications);
        return of(updatedNotifications);
      }
    ));
  }

  /**
   * Delete the notification
   *
   * @param id
   */
  delete(id: string): Observable<boolean> {
    return this.notifications$.pipe(
      take(1),
      switchMap((notifications) =>
        this._httpClient
          .delete<boolean>('api/common/notifications', { params: { id } })
          .pipe(
            map((isDeleted: boolean) => {
              // Find the index of the deleted notification
              const index = notifications.findIndex((item) => item.id === id);

              // Delete the notification
              notifications.splice(index, 1);

              // Update the notifications
              this._notifications.next(notifications);

              // Return the deleted status
              return isDeleted;
            }),
          ),
      ),
    );
  }

  /**
   * Mark all notifications as read
   */
  markAllAsRead(): Observable<boolean> {
    return this.notifications$.pipe(
      take(1),
      switchMap((notifications) =>
        this._httpClient
          .get<boolean>('api/common/notifications/mark-all-as-read')
          .pipe(
            map((isUpdated: boolean) => {
              // Go through all notifications and set them as read
              notifications.forEach((notification, index) => {
                notifications[index].read = true;
              });

              // Update the notifications
              this._notifications.next(notifications);

              // Return the updated status
              return isUpdated;
            }),
          ),
      ),
    );
  }

  public connect() {
    const url = `${environment.apiUrl}/notifications/subscribe?token=${this.token}`;
    this.eventSource = new EventSource(url);

    this.eventSource.onerror = (error) => {
      console.error('EventSource failed:', error);
    };

    this.eventSource.onmessage = (event) => {
      this._zone.run(() => {
        try {
          const data = JSON.parse(event.data);
          this.addNotifications(data).subscribe();
        } catch (e) {
          console.error('Error parsing event data:', e);
        }
      });
    };
  }

  public cleanup() {
    if (this.eventSource) {
      this.eventSource.close();
    }
  }
}
