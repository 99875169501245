import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { IPaginate } from '../../types/paginate/paginate.types';
import { map, Observable } from 'rxjs';
import {
  ContentTagType,
  LastNewsletterTimestampType, NewsletterDocumentType,
  NewslettersType,
  NewsletterType,
  PartialNewsletterType,
} from "../../types/social/social.types";
import {
  QUERY_CAN_CREATE_NEWSLETTER,
  QUERY_GET_ALL_NEWSLETTERS,
  QUERY_GET_CONTENT_TAGS, QUERY_GET_EXTERNAL_NEWSLETTERS,
  QUERY_GET_LAST_NEWSLETTER_TIMESTAMPS,
  QUERY_GET_NEWSLETTER_BY_ID,
} from '../../core/graphql/queries';
import { environment } from '../../../environments/environment';
import {
  MUTATION_ALLOW_COMMENTS_TO_NEWSLETTER,
  MUTATION_BLOCK_COMMENTS_TO_NEWSLETTER,
  MUTATION_CREATE_REACTION_FOR_NEWSLETTER_ID,
  MUTATION_DELETE_COMMENT_FOR_NEWSLETTER_ID,
  MUTATION_DELETE_DOCUMENTS,
  MUTATION_DELETE_NEWSLETTER_BY_ID,
  MUTATION_DELETE_REACTION_FOR_NEWSLETTER_ID,
  MUTATION_DOWNLOAD_DOCUMENT,
  MUTATION_UPLOAD_DOCUMENTS,
} from "../../core/graphql/mutations";
import { IEntityHistory } from "../sales/pipeline/pipeline.interface";

@Injectable({
  providedIn: 'root',
})
export class SocialService {
  constructor(
    private _apollo: Apollo,
    private _http: HttpClient,
  ) {}

  getAllNewsletters(
    type: string,
    paginate: IPaginate = null,
  ): Observable<NewslettersType> {
    return this._apollo
      .query({
        query: QUERY_GET_ALL_NEWSLETTERS,
        variables: {
          type,
          paginate,
        },
      })
      .pipe(map((result: any) => result.data.getAllNewsletters));
  }

  getExternalNewsletters(
    paginate: IPaginate = null,
  ): Observable<NewslettersType> {
    return this._apollo
      .query({
        query: QUERY_GET_EXTERNAL_NEWSLETTERS,
        variables: {
          paginate,
        },
      })
      .pipe(map((result: any) => result.data.getExternalNewsletters));
  }

  getLastNewsletterTimestamp(): Observable<LastNewsletterTimestampType[]> {
    return this._apollo
      .query({
        query: QUERY_GET_LAST_NEWSLETTER_TIMESTAMPS,
        variables: {},
      })
      .pipe(map((result: any) => result?.data?.getLastNewsletterTimestamps));
  }

  getContentTags(): Observable<ContentTagType[]> {
    return this._apollo
      .query({
        query: QUERY_GET_CONTENT_TAGS,
        variables: {},
      })
      .pipe(map((result: any) => result?.data?.getContentTags));
  }

  getNewsletterById(id: string): Observable<NewsletterType> {
    return this._apollo
      .query({
        query: QUERY_GET_NEWSLETTER_BY_ID,
        variables: {
          id,
        },
      })
      .pipe(map((result: any) => result.data.getNewsletterById));
  }

  createNewsletter(newsletterData: any): Observable<PartialNewsletterType> {
    return this._http.post(environment.graphqlUrl, newsletterData.formData);
  }

  editNewsletter(newsletterData: any): Observable<PartialNewsletterType> {
    return this._http.post(environment.graphqlUrl, newsletterData.formData);
  }

  deleteNewsletter(id: string): Observable<boolean> {
    return this._apollo
      .mutate({
        mutation: MUTATION_DELETE_NEWSLETTER_BY_ID,
        variables: {
          id,
        },
      })
      .pipe(map((result: any) => result.data.deleteNewsletter));
  }

  allowCommentsToNewsletter(newsletterId: string): Observable<boolean> {
    return this._apollo
      .mutate({
        mutation: MUTATION_ALLOW_COMMENTS_TO_NEWSLETTER,
        variables: {
          newsletterId,
        },
      })
      .pipe(map((result: any) => result.data.allowCommentsToNewsletterId));
  }

  blockCommentsToNewsletterId(newsletterId: string): Observable<boolean> {
    return this._apollo
      .mutate({
        mutation: MUTATION_BLOCK_COMMENTS_TO_NEWSLETTER,
        variables: {
          newsletterId,
        },
      })
      .pipe(map((result: any) => result.data.blockCommentsToNewsletterId));
  }

  createReactionForNewsletterId(
    newsletterId: string,
    reaction: string,
  ): Observable<NewsletterType> {
    return this._apollo
      .mutate({
        mutation: MUTATION_CREATE_REACTION_FOR_NEWSLETTER_ID,
        variables: {
          newsletterId,
          reaction,
        },
      })
      .pipe(map((result: any) => result.data.createReactionForNewsletterId));
  }

  deleteReactionForNewsletterId(
    newsletterId: string,
  ): Observable<NewsletterType> {
    return this._apollo
      .mutate({
        mutation: MUTATION_DELETE_REACTION_FOR_NEWSLETTER_ID,
        variables: {
          newsletterId,
        },
      })
      .pipe(map((result: any) => result.data.deleteReactionForNewsletterId));
  }

  createCommentForNewsletterId(newsletterData: any): Observable<any> {
    return this._http.post(environment.graphqlUrl, newsletterData);
  }

  deleteCommentForNewsletterId(
    newsletterId: string,
    commentId: string,
  ): Observable<NewsletterType> {
    return this._apollo
      .mutate({
        mutation: MUTATION_DELETE_COMMENT_FOR_NEWSLETTER_ID,
        variables: {
          newsletterId,
          commentId,
        },
      })
      .pipe(map((result: any) => result.data.deleteCommentForNewsletterId));
  }

  canCreateNewsletter(
    branchMemberId: string,
    newsletterType: string,
  ): Observable<boolean> {
    return this._apollo
      .query({
        query: QUERY_CAN_CREATE_NEWSLETTER,
        variables: {
          branchMemberId,
          newsletterType,
        },
      })
      .pipe(map((result: any) => result.data.canCreateNewsletter));
  }

  uploadDocuments(documents: NewsletterDocumentType[]): Promise<NewsletterDocumentType[]> {
    const operations = {
      query: MUTATION_UPLOAD_DOCUMENTS,
    };

    const _map = {};
    const formData = new FormData();
    formData.append('operations', JSON.stringify(operations));
    documents.forEach((document, index) => {
      _map[index] = [`variables.files.${index}`];
    });
    formData.append('map', JSON.stringify(_map));

    documents.forEach((document, index) => {
      formData.append(index.toString(), document?.file);
    })
    return this._http.post(environment.graphqlUrl, formData).pipe(map((result: any) => result.data.uploadDocuments)).toPromise();
  }

  deleteDocuments(
    documents: NewsletterDocumentType[]
  ): Observable<NewsletterType> {
    return this._apollo
    .mutate({
      mutation: MUTATION_DELETE_DOCUMENTS,
      variables: {
        documents,
      },
    })
    .pipe(map((result: any) => result.data.deleteDocuments));
  }

  downloadDocument(fileName: string): Observable<string> {
    return this._apollo
    .mutate({
      mutation: MUTATION_DOWNLOAD_DOCUMENT,
      variables: {
        fileName
      },
    })
    .pipe(
      map((result: any) => result.data.downloadDocument));
  }

  searchNewsletters(query: string, type: string): Observable<NewslettersType> {
    return this._http.get<NewslettersType>(`${environment.apiUrl}/search/newsletters?query=${query}&type=${type}`);
  }
}
